import React, { useEffect, useState } from "react";
import { Form, Input, Button, Alert, message, notification } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useUserStore from "../context/userInformatioContext";
type NotificationType = "success" | "info" | "warning" | "error";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { updateUser, updateToken } = useUserStore();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [errorMessage, setErrorMessage] = useState("");

  const onFinish = async (values: any) => {
    await axios
      .post(`${baseUrl}auth/login`, {
        email: values?.Email,
        password: values?.password,
      })
      .then(async (res) => {
        await updateUser(res.data.user);
        await updateToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        navigate("/dashboard");
      })
      .catch((err) => {
        setErrorMessage(
          err?.response?.data?.message ||
            "An error occurred while trying to login"
        );
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    errorMessage &&
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
  }, [errorMessage]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <p
        style={{
          fontSize: "96px",
          fontWeight: 600,
          margin: "0 1rem",
          color: "#C0CFB2",
          position: "absolute",
          top: "27px",
          left: "63px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        sage
      </p>

      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ maxWidth: 300 }}
      >
        <Form.Item
          label="Email"
          name="Email"
          rules={[{ required: true, message: "Please input your email!" }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.Password />
        </Form.Item>
        {!!errorMessage && (
          <Alert message={errorMessage} type="error" showIcon closable />
        )}

        <Form.Item name="remember" valuePropName="checked">
          <a href="create-account">Create account</a>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
