import React, { useState } from "react";
import { Button, Radio, Table } from "antd";
import { useNavigate } from "react-router-dom";
import useUserStore from "../context/userInformatioContext";
import axios from "axios";
const questions = [
  "I feel confident in my ability to handle unexpected financial challenges.",

  "I have sufficient financial resources to absorb potential losses in my investments.",

  "I can quickly liquidate investments if needed without substantial financial harm.",

  "I am willing to take substantial financial risks to achieve high returns.",

  "I am comfortable investing in high-volatility markets.",

  "I prefer high-risk, high-reward investment opportunities over safer, lower-return ones.",

  "I remain calm and stick to my investment strategy during market downturns.",

  "I can manage my emotions effectively when my investments perform poorly.",

  "I am confident in my long-term investment plan, even when the market is volatile.",
  "I am more concerned about potential losses than potential gains when making investment decisions.",

  "I often choose lower-risk investments to avoid the possibility of loss.",

  "The fear of losing money impacts my investment choices more than the hope of making money.",

  "I often make investment decisions quickly without thoroughly considering all the potential risks and benefits.",

  "I make investing decisions quickly, without considering all the options.",

  "I tend to spend money impulsively, without planning or budgeting.",
];

const options = [
  "Strongly disagree",
  "Somewhat disagree",

  "Somewhat agree",
  "Strongly agree",
];

const Survey: React.FC = () => {
  const { token } = useUserStore();
  const [responses, setResponses] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleOptionChange = (question: string, value: string) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [question]: value,
    }));
  };
  const columns = [
    {
      title: "",
      dataIndex: "question",
      key: "question",
    },
    ...options.map((option) => ({
      title: option,
      dataIndex: option,
      key: option,
      render: (_: any, record: any) => (
        <div style={{ textAlign: "center" }}>
          <Radio.Group
            onChange={(e) =>
              handleOptionChange(record.question, e.target.value)
            }
            value={responses[record.question]}
            buttonStyle="solid"
            size="small"
            className="radio-bordered" // Aplica la clase aquí
            optionType="button"
          >
            <Radio
              value={option}
              style={{
                borderRadius: "60px",
                height: "20px",
                width: "20px",
              }}
            ></Radio>
          </Radio.Group>{" "}
        </div>
      ),
    })),
  ];

  const data = questions.map((question, index) => ({
    key: index,
    question,
  }));
  const onFinish = async () => {
    const formattedResponses = questions.map((question, index) => ({
      question,
      value:
        responses[question] === "Strongly disagree"
          ? 1
          : responses[question] === "Somewhat disagree"
          ? 2
          : responses[question] === "Somewhat agree"
          ? 3
          : responses[question] === "Strongly agree"
          ? 4
          : 1,
    }));
    await axios
      .patch(
        `${baseUrl}api/survey`,
        {
          questions: formattedResponses,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        navigate("/dashboard");
      });

    // navigate("/dashboard");
  };

  return (
    <div className="survey">
      <p
        style={{
          fontSize: "96px",
          fontWeight: 600,
          margin: "0 1rem",
          color: "#C0CFB2",
          position: "absolute",
          top: "27px",
          left: "63px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        sage
      </p>
      <div
        style={{
          marginTop: "10rem",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          We’re almost done, just a few questions about your risk preferences
        </p>
        <p
          style={{
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Please indicate your level of agreement to the following statements
        </p>
        <Table columns={columns} dataSource={data} pagination={false} />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
          }}
        >
          <Button
            type="primary"
            style={{
              margin: "1rem",
              marginRight: "8rem",
              marginBottom: "2rem",
            }}
            onClick={() => onFinish()}
          >
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Survey;
