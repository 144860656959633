import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Image,
  Layout,
  Menu,
  Radio,
  Row,
  theme,
} from "antd";

import type { MenuProps } from "antd";
import { Link, useNavigate } from "react-router-dom";

const { Header, Content, Footer } = Layout;
const MainScreen = () => {
  const navigate = useNavigate();
  const createAccount = () => {
    navigate("/create-account");
  };
  type MenuItem = Required<MenuProps>["items"][number];
  const [current, setCurrent] = useState("mail");

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const items: MenuItem[] = [
    {
      label: <p className="header-option">About</p>,
      key: "about",
      // icon: <MailOutlined />,
    },
    {
      label: <p className="header-option">Learn</p>,
      key: "learn",
      // icon: <AppstoreOutlined />,
      // disabled: true,
    },
    {
      label: <p className="header-option">RiskAnalyzer(TM)</p>,
      key: "riskAnalyzer",
      // icon: <SettingOutlined />,
    },
    {
      key: "Log in",
      label: (
        <Link to={`log-in`}>
          {" "}
          <p className="header-option">Log in</p>
        </Link>
      ),
      // icon: <SettingOutlined />,
    },
    {
      label: (
        <Button
          type="primary"
          style={{
            marginTop: "1.5rem",
          }}
          onClick={() => createAccount()}
        >
          Create
        </Button>
      ),
      key: "create",
      // icon: <SettingOutlined />,
    },
    // {
    //   key: "alipay",
    //   label: (
    //     <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
    //       Navigation Four - Link
    //     </a>
    //   ),
    // },
  ];
  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          background: "white",
          color: "black",
          height: "116px",
        }}
      >
        <p
          style={{
            fontSize: "96px",
            fontWeight: 600,
            margin: "0 1rem",
            color: "#C0CFB2",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          sage
        </p>
        <Menu
          // theme="dark"
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            background: "white",
            color: "black",
          }}
        />
      </Header>
      <Content style={{ padding: "0 0px", background: "#ffffff" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,

            borderRadius: borderRadiusLG,
          }}
        >
          <Row
            gutter={[16, 48]}
            style={{
              marginBottom: "10rem",
              padding: "3rem",
            }}
          >
            <Col
              className="gutter-row"
              span={12}
              style={{
                textAlign: "initial",
              }}
            >
              <p
                style={{
                  fontSize: "48px",
                  fontWeight: "500",
                }}
              >
                Finally, a free retirement calculator that doesn’t suck{" "}
              </p>

              <Button type="primary" onClick={() => createAccount()}>
                Create a plan
              </Button>
            </Col>
            <Col
              className="gutter-row"
              span={12}
              style={{
                textAlign: "center",
                paddingTop: "3rem",
              }}
            >
              <Image
                src="https://via.placeholder.com/900x500"
                width="80%"
                style={{}}
              />
            </Col>
          </Row>
          <Row
            gutter={[16, 48]}
            style={{
              marginBottom: "5rem",
              background: "#003333",
              clipPath:
                "polygon(22% 2%,24.00% 10.00%,52% 10%,80% 10%,100% 10%,100% 90%,81% 90%,79% 100%,77.00% 90.00%,51% 90%,20% 90%,0% 90%,0% 10%,20.00% 10.00%)",
              color: "white",
              padding: "3rem",
              paddingTop: "4rem",
              paddingBottom: "6rem",
            }}
          >
            <Col
              className="gutter-row"
              span={12}
              style={{
                textAlign: "initial",
              }}
            >
              <p
                style={{
                  fontSize: "36px",
                  fontWeight: 600,
                }}
              >
                How much do you need to save for retirement?
              </p>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                The entire financial planning industry is based on answering
                this one seemingly simple question. However, you can get most of
                the way there by answering a few simple questions about
                yourself.{" "}
              </p>
            </Col>
            <Col
              className="gutter-row"
              span={12}
              style={{
                textAlign: "center",
                paddingTop: "3rem",
              }}
            >
              <Image
                src="https://via.placeholder.com/900x500"
                width="80%"
                style={{}}
              />
            </Col>
          </Row>
          <Row
            gutter={[16, 48]}
            style={{
              padding: "3rem",
            }}
          >
            <Col
              className="gutter-row"
              span={12}
              style={{
                textAlign: "center",
                paddingTop: "3rem",
              }}
            >
              <Image
                src="https://via.placeholder.com/900x500"
                width="80%"
                style={{
                  marginTop: "3rem",
                }}
              />
            </Col>
            <Col
              className="gutter-row"
              span={12}
              style={{
                textAlign: "initial",
              }}
            >
              <p
                style={{
                  fontSize: "36px",

                  fontWeight: 600,
                }}
              >
                How it works
              </p>
              <p
                style={{
                  fontSize: "24px",
                  // fontWeight: 600,
                }}
              >
                Sign up for free <br />
                Answer a few questions about yourself <br />
                Start saving
              </p>
              <p
                style={{
                  fontSize: "24px",
                  // fontWeight: "bold",
                }}
              >
                Update your plan as your life changes or you hit new savings
                milestones to track your progress.
              </p>
              <Button type="primary" onClick={() => createAccount()}>
                Create
              </Button>
            </Col>
          </Row>
          <div
            style={{
              background: "#F5F6F6",
              padding: "3rem",
            }}
          >
            <Row gutter={[16, 48]}>
              <Col
                className="gutter-row"
                span={24}
                style={{
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "36px",
                    fontWeight: 600,
                  }}
                >
                  How to set financial goals{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "24px",
                      // fontWeight: 600,
                      width: "60%",
                    }}
                  >
                    A sage plan enables you to set SMART savings goals. Stop
                    wondering how much is enough so you can get on with taking
                    concrete steps to achieving your goal.
                  </p>
                </div>
              </Col>
            </Row>
            <Row
              gutter={[16, 48]}
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "24px",
                // fontWeight: 600,
              }}
            >
              <div
                style={{
                  width: "18%",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    // fontWeight: "bold",
                  }}
                >
                  Specific
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    // fontWeight: "bold",
                  }}
                >
                  Define exactly what you're saving for—maybe it's an emergency
                  fund, a vacation, or a down payment. The clearer your goal,
                  the easier it is to stay motivated and measure progress.
                </p>
              </div>
              <div
                style={{
                  width: "18%",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    // fontWeight: "bold",
                  }}
                >
                  Measurable
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    // fontWeight: "bold",
                  }}
                >
                  Attach a number to your goal, like saving $5,000 in a year.
                  This helps you track how close you are to reaching your
                  target, keeping you accountable.
                </p>
              </div>
              <div
                style={{
                  width: "18%",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                  }}
                >
                  Achievable
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    // fontWeight: "bold",
                  }}
                >
                  Make sure your goal is realistic based on your current income
                  and expenses. You want a challenge, but not something so tough
                  that you feel defeated right away.
                </p>
              </div>
              <div
                style={{
                  width: "18%",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                  }}
                >
                  Relevant
                </p>
                <p
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Ask yourself why this goal matters to you. If it's
                  important—like feeling more secure or being able to travel
                  comfortably—it'll be easier to stay committed.
                </p>
              </div>
              <div
                style={{
                  width: "18%",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                  }}
                >
                  Time-bound
                </p>
                <p
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Give yourself a deadline, like saving $5,000 by the end of the
                  year. Deadlines keep you focused and help break your goal into
                  smaller, manageable steps along the way.
                </p>
              </div>
            </Row>
          </div>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Sage app©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default MainScreen;
