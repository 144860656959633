import { Col, Row, Tooltip } from "antd";
import useUserStore from "../context/userInformatioContext";
import axios from "axios";
import { useEffect } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const MyPlan = () => {
  const { updateUser, token, updateToken, user } = useUserStore();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const userInfo = async () => {
    axios
      .get(
        `${baseUrl}api/user`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        updateUser(res.data);
        // navigate("/dashboard");
      });
  };
  useEffect(() => {
    localStorage.setItem("menuKey", "my-plan");
    token ? userInfo() : updateToken(localStorage.getItem("token") || "");
  }, [token]);
  return (
    <div>
      <Row gutter={[16, 48]}>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
          <div>
            <p className="sub-title">Savings target</p>
            <p className="value-paragraph">
              {Math.ceil(user.retirementSavingsTarget)?.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
              /month
            </p>
            <p className="obervations">
              Congratulations, you’re on track to have enough to save for
              retirement
            </p>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
          <div>
            <p className="sub-title">Actual savings</p>
            <p className="value-paragraph">
              {user?.currentSavings?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" xs={24} sm={24} md={10} lg={10}>
          <div>
            <p className="sub-title">Retirement nest egg</p>
            <p className="value-paragraph">
              {user?.retirementNestEgg?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
            <p className="obervations">
              By the time you want to retire, you’ll have $1.5 million.{" "}
            </p>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={14} lg={14}>
          <Row
            justify={"center"}
            style={{
              marginTop: "1rem",
              height: "250px",
              width: "100%",
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={200}
                height={200}
                data={user?.savingsPathData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name">
                  <Label value="years" position="bottom" />
                </XAxis>
                <YAxis />
                {/* <Legend height={50} align="center" /> */}
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" xs={24} sm={24} md={10} lg={10}>
          <div>
            <p className="sub-title">Retirement spending target</p>
            <p className="value-paragraph">
              {" "}
              {user?.optimalMonthlyNetIncomeRetirement?.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
              /month
            </p>
            <p className="obervations">
              By spending this amount each month, you’re money won’t run out
              until you’re 105.
            </p>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={14} lg={14}>
          <Row
            justify={"center"}
            style={{
              marginTop: "1.5rem",
            }}
          >
            <img src="https://via.placeholder.com/500x200" alt="" />
          </Row>
        </Col>
      </Row>
      <Row justify={"space-between"}>
        <Col className="gutter-row" span={24}>
          <p className="value-paragraph">Compared to People Like You</p>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={6} lg={6}>
          <div>
            <p className="sub-title">Income</p>
            <p className="value-paragraph">55th percentile</p>
            <p className="obervations">
              You make more than 55% of people your age in your country.
            </p>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={6} lg={6}>
          <div>
            <p className="sub-title">Savings</p>
            <p className="value-paragraph">98th</p>
            <p className="obervations">
              Nice! You have saved more than 98% of people your age in your
              country.
            </p>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={6} lg={6}>
          <div>
            <p className="sub-title">Debt</p>
            <p className="value-paragraph">10th</p>
            <p className="obervations">
              Nice! 90% of people your age have more debt than you.{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MyPlan;
