import { Col, Row } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import useUserStore from "../context/userInformatioContext";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";

const RiskProfile = () => {
  const { token, updateToken } = useUserStore();
  const navigation = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [riskProfileData, setRiskProfileData] = useState([]);
  useEffect(() => {
    localStorage.setItem("menuKey", "risk-profile");
    if (!!token) {
      try {
        axios
          .get(`${baseUrl}api/risk-profile`, {
            headers: {
              Authorization: `Bearer ${token || localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setRiskProfileData(res.data);
          })
          .catch((err) => {
            if (err?.response?.status === 401) {
              navigation("/dashboard");
            }
          });
      } catch (error) {
        console.log("error", error);
      }
    } else {
      updateToken(localStorage.getItem("token") || "");
    }
  }, [token]);

  return (
    <div>
      <Row gutter={[16, 48]}>
        <p className="sub-title">Portfolio Strategy</p>
      </Row>

      <Row gutter={[16, 48]}>
        <Col className="gutter-row" span={24}>
          <div>
            <p className="value-paragraph">Balanced</p>

            <Row justify={"center"}>
              <img src="https://via.placeholder.com/500x200" alt="" />
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col className="gutter-row" span={24}>
          <div>
            <p className="sub-title">Risk Profile</p>

            <Row
              justify={"center"}
              style={{
                height: "400px",
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  data={riskProfileData}
                  height={400}
                  width={400}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <Radar
                    name="Value"
                    dataKey="A"
                    stroke="#8884d8"
                    fill="#8884d8"
                    fillOpacity={0.6}
                  />
                </RadarChart>
              </ResponsiveContainer>
            </Row>
            <p className="obervations">
              sage plans use a risk profiling methodology that is based on
              decades of scientific research to estimate a portfolio that is
              right for you.{" "}
            </p>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col className="gutter-row" span={24}>
          <div>
            <p className="sub-title">Capacity</p>
            <p className="obervations-weight-400">
              Risk capacity is the ability for you to take on financial risk.
              This is an objective measure.{" "}
            </p>

            <p className="sub-title">Tolerance</p>
            <p className="obervations-weight-400">
              Risk tolerance is your willingness to take on investment risk.
            </p>

            <p className="sub-title">Impulsivity</p>
            <p className="obervations-weight-400">
              Impulsivity measures how quickly you’re likely to take action when
              you get stressed about money.{" "}
            </p>

            <p className="sub-title">Composure</p>
            <p className="obervations-weight-400">
              Risk tolerance is the level of volatility that you feel
              comfortable with in your portfolio.{" "}
            </p>

            <p className="sub-title">Loss Aversion</p>
            <p className="obervations-weight-400">
              Loss aversion in an emotional bias that influences the way you
              feel about ups and downs in your portfolio.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RiskProfile;
