import { create } from "zustand";

export interface UserState {
  user: User;
  token: string;
  updateUser: (user: User) => void;
  updateToken: (token: string) => void;
}

export interface User {
  email: string;
  role: string;
  currency: string;
  uuid: string;
  questions: Question[];
  normalizeScore: NormalizeScore[];
  __v: number;
  country: string;
  currentAge: number;
  currentDebt: number;
  currentSavings: number;
  debtRepaymentPerMonth: number;
  incomeAfterTax: number;
  retireAge: number;
  retirementNestEgg: number;
  savingsPerMonth: number;
  retirementSavingsTarget: number;
  savingsPathData?: any[];
  optimalMonthlyNetIncomeRetirement: number;
}

export interface NormalizeScore {
  metric: string;
  score: number;
  _id: string;
}

export interface Question {
  question: string;
  value: number;
  _id?: string;
}

const useUserStore = create<UserState>()((set) => ({
  user: {
    email: "",
    role: "",
    currency: "",
    uuid: "",
    questions: [],
    __v: 0,
    normalizeScore: [],
    country: "",
    currentAge: 0,
    currentDebt: 0,
    currentSavings: 0,
    debtRepaymentPerMonth: 0,
    incomeAfterTax: 0,
    retireAge: 0,
    retirementNestEgg: 0,
    savingsPerMonth: 0,
    retirementSavingsTarget: 0,
    savingsPathData: [],
    optimalMonthlyNetIncomeRetirement: 0,
  },
  token: "",
  updateUser: (user) => set({ user }),
  updateToken: (token) => set({ token }),
}));

export default useUserStore;
