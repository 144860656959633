import { useEffect, useState } from "react";

import { Button, Layout, Menu, theme } from "antd";
import MyPlan from "./MyPlan";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import RiskProfile from "./RiskProfile";
import useUserStore from "../context/userInformatioContext";
import axios from "axios";
const Dashboard = () => {
  const { Header, Sider, Content } = Layout;
  const { updateUser, token, updateToken } = useUserStore();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const userInfo = async () => {};
  useEffect(() => {
    token ? userInfo() : updateToken(localStorage.getItem("token") || "");
  }, [userInfo]);

  return (
    <Layout
      style={{
        height: "100%",
      }}
    >
      <Sider
        trigger={null}
        style={{
          background: "#003333",
          color: "white",
        }}
      >
        {/* <Sider trigger={null} collapsible collapsed={collapsed}> */}
        <p
          style={{
            fontSize: "96px",
            fontWeight: 600,
            margin: "0 1rem",
            color: "#C0CFB2",
            padding: "2rem",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          sage
        </p>
        <Menu
          mode="inline"
          defaultSelectedKeys={[localStorage.getItem("menuKey") || "my-plan"]}
          title="sage"
          style={{
            background: "#003333",
            color: "white",
          }}
          items={[
            {
              key: "my-plan",
              //   icon: <UserOutlined />,
              // label: <a href={`my-plan/`}>My plan</a>,
              label: <Link to={`/dashboard`}>My plan</Link>,
            },
            {
              key: "2",
              //   icon: <VideoCameraOutlined />,
              disabled: true,
              label: "Update my plan",
            },
            {
              key: "risk-profile",
              //   icon: <UploadOutlined />,
              label: <Link to={`risk-profile`}>Risk profile</Link>,
            },
            {
              key: "4",
              disabled: true,
              //   icon: <UploadOutlined />,
              // label: <a href={`account-summary`}>Account Summary</a>,
              label: "Account Summary",
              // label: <Link to={`account-summary`}>Account Summary</Link>,
            },
            {
              key: "5",
              disabled: true,
              //   icon: <UploadOutlined />,
              label: "Asumptions",
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{ padding: 0, background: colorBgContainer, display: "flex" }}
        >
          <Button
            type="text"
            // icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Routes>
            <Route path="/" element={<MyPlan />} />
            <Route path="/risk-profile" element={<RiskProfile />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
